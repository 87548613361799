import React from 'react';
import Layout from '../templates/layout';
import useScreenSize from 'use-screen-size';
import TermsTransformer from '../components/terms/transformer';

const TermsOfService = (props) => {
  const screenSize = useScreenSize();
  return (
    <Layout
      buttonType='help'
      screenSize={screenSize}
      path={props.location.pathname}
    >
      <div className='flex py-[125px] px-[16px] '>
        <TermsTransformer terms={terms} title={'Terms of Service'} />
      </div>
    </Layout>
  );
};
const terms = [
  {
    title: null,
    paragraphs: [
      'Welcome to Careery! By using our services, you agree to be bound by the following Terms of Service. Please read these terms carefully, as they contain important information about your rights and obligations.',
    ],
  },
  {
    title: 'Acceptance of Terms',
    paragraphs: [
      `These Terms of Service ("Terms") constitute a legally binding agreement between you and Careery OÜ, a company registered in the Republic of Estonia, (hereinafter the “Careery”. By accessing, browsing, or using our website and services, you acknowledge that you have read, understood, and agreed to be bound by these Terms. If you do not agree to these Terms, you are not authorized to use our services.`,
    ],
  },
  {
    title: 'Modifications to Terms',
    paragraphs: [
      'Careery reserves the right to modify these Terms at any time without prior notice. Your continued use of our services after any such modifications have been made will constitute your acceptance of the new Terms. We encourage you to periodically review these Terms to stay informed about any changes.',
    ],
  },
  {
    title: 'Eligibility',
    paragraphs: [
      'You must be at least 18 years old to use our services. By accessing or using our services, you represent and warrant that you are 18 years of age or older and have the legal capacity to enter into a binding contract.',
    ],
  },
  {
    title: 'Services',
    paragraphs: [
      'Careery provides a variety of career-related services, including resume writing, job application assistance, interview preparation, salary maximization, and tailored consultations and more. These services are subject to the terms and conditions set forth in these Terms and any additional terms that may be provided at the time of service registration.',
    ],
  },
  {
    title: 'Fees and Payments',
    paragraphs: [
      "All fees for Careery's services are non-refundable. You agree to pay all applicable fees for the services you select, as well as any applicable taxes. Payment must be made in advance and in full for all services. You acknowledge and agree that Careery may change the fees for its services at any time without prior notice.",
    ],
  },
  {
    title: 'CareerLeap Income Share',
    paragraphs: [
      "If you choose the CareerLeap Package, you agree to share 15% of your first month's income from a job secured with our assistance. This income share is in addition to the one-time registration fee of $675. You must provide accurate and up-to-date information about job offers, accepted positions, and income changes within 14 days of receiving such information, both voluntarily and upon request from our team. Upon our request, you agree to provide your tax returns as proof of income and employment status.",
    ],
  },
  {
    title: 'Confidentiality',
    paragraphs: [
      'Careery will take reasonable steps to protect your personal information, as described in our Privacy Policy. By using our services, you consent to the collection, use, and disclosure of your personal information in accordance with our Privacy Policy.',
    ],
  },
  {
    title: 'Intellectual Property',
    paragraphs: [
      'All content, including but not limited to text, graphics, logos, icons, images, and software, is the property of Careery or its content suppliers and is protected by international copyright laws. You may not reproduce, distribute, or create derivative works from our content.',
    ],
  },
  {
    title: 'Termination',
    paragraphs: [
      'Careery reserves the right to terminate your access to our services at any time for any reason, including but not limited to your breach of these Terms. Upon termination, your right to use our services will cease immediately, and you must destroy any copies of our content in your possession.',
    ],
  },
  {
    title: 'Limitation of Liability',
    paragraphs: [
      'In no event shall Careery be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from your access to or use of our services or any content available through our services.',
    ],
  },
  {
    title: 'Governing Law',
    paragraphs: [
      'These Terms and any disputes arising out of or relating to these Terms or your use of our services shall be governed by the laws of Estonia, without regard to its conflict of law provisions. Careery OU, an Estonian company, is the legal entity responsible for providing the services under these Terms.',
    ],
  },
  {
    title: 'Contact Information',
    paragraphs: [
      'If you have any questions or concerns about these Terms or our services, please contact us at [hi@careery.com]. We will make every effort to address your inquiries and resolve any issues you may have.',
    ],
  },
  {
    title: 'Entire Agreement',
    paragraphs: [
      'These Terms, together with our Privacy Policy and any additional terms and conditions that may be provided at the time of service registration, constitute the entire agreement between you and Careery and govern your use of our services, superseding any prior agreements or understandings between you and Careery.',
    ],
  },
  {
    title: 'Severability',
    paragraphs: [
      "If any provision of these Terms is found by a court of competent jurisdiction to be invalid or unenforceable, the parties agree that the remaining provisions shall remain in full force and effect, and the invalid or unenforceable provision shall be deemed replaced by a valid, enforceable provision that most closely reflects the parties' intent.",
    ],
  },
  {
    title: 'No Waiver',
    paragraphs: [
      'No waiver of any term or condition of these Terms by Careery shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure by Careery to assert a right or provision under these Terms shall not constitute a waiver of such right or provision.',
    ],
  },
  {
    title: 'Assignment',
    paragraphs: [
      'You may not assign or transfer any rights or obligations under these Terms without the prior written consent of Careery. Careery may assign or transfer its rights and obligations under these Terms without restriction.',
    ],
  },
  {
    title: 'Force Majeure',
    paragraphs: [
      'Careery shall not be liable for any delay or failure in performance under these Terms resulting from acts of God, war, terrorism, labor disputes, strikes, natural disasters, or any other event beyond our reasonable control.',
    ],
  },
];

export default TermsOfService;
